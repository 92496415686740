import type { MyCustomerUser } from '~/types/models/mycustomer';

const users = ref<MyCustomerUser[]>([]);
const currentPage = ref(1);
const failed = ref(false);
const loading = ref(false);
const loggingAs = ref('');

export function useMyCustomer() {
    const { apiClient } = useShopwareContext();

    const limit = 50;
    const count = computed(() => users.value.length ?? 0);
    const totalPages = computed(() => Math.ceil(count.value / limit));

    const searchUsers = async (searchTerm: string) => {
        try {
            if (loading.value || !searchTerm || searchTerm.length <= 2) return;
            loading.value = true;

            const fetchedUsers = await apiClient.invoke('searchCustomerAccounts get /my-customer/search?search', {
                search: searchTerm,
            });

            users.value = [...fetchedUsers];
        } catch (error) {
            Logger.captureException(error);
            failed.value = true;
        } finally {
            loading.value = false;
        }

        return users.value;
    };

    /** requests search route without param to check if user is eligible to access mycustomer page.
     * Backend Route checks if user is logged in, has my_customer_account flag or isDelegatedLoginSession and throws 'not allowed error' otherwise.
     * Because we cant check isDelegatedLoginSession (session.payload) in frontend */
    const validateAccess = async () => {
        try {
            await apiClient.invoke('searchCustomerAccounts get /my-customer/search', {});
        } catch (error: any) {
            // status codes are messed up. Shopware sends code 403 for both notLoggedIn and insufficientPermissions. While our custom error uses code 401.
            if (error.status === 403) return { success: false, status: 403 }; // not logged in
            if (error.status === 401) return { success: false, status: 401 }; // error.status 500, not sufficient permissions
        }

        return { success: true }; // error.status 500, 'Minimum search term length' is ok
    };

    // there is no backend pagination, so we do it manually
    const paginatedUsers = computed(() => {
        const startIndex = (currentPage.value - 1) * limit;
        const endIndex = startIndex + limit;
        return users.value.slice(startIndex, endIndex);
    });

    const goToPage = (pageNumber: number) => {
        currentPage.value = Math.max(1, Math.min(pageNumber, totalPages.value));
    };

    const loginAsUser = async (globalAccountId: string) => {
        try {
            if (loggingAs.value || !globalAccountId) return;
            loggingAs.value = globalAccountId;

            await apiClient.invoke('delegatedLogin post /mycustomer/delegated-login?globalContactId', {
                globalContactId: globalAccountId,
            });
        } catch (error) {
            Logger.captureException(error);
        } finally {
            loggingAs.value = '';
        }
    };

    return {
        paginatedUsers,
        goToPage,
        searchUsers,
        validateAccess,
        loginAsUser,
        count,
        limit,
        totalPages,
        currentPage,
        failed,
        loading,
        loggingAs,
    };
}
